<template>
  <div class="container_nav box_size">
    <div class="el-container">
      <!--      左边-->
      <div class="el-aside" style="width: 300px">
        <div class="left_wd">帮助文档</div>
        <a-tree
          show-line
          v-if="treeData.length > 0"
          :tree-data="treeData"
          :autoExpandParent="true"
          :multiple="false"
          :defaultExpandedKeys="expandedKeys"
          :defaultSelectedKeys="selectedKeys"
          :replace-fields="replaceFields"
          @select="onSelect"
        >
          <a-icon slot="switcherIcon" type="down" />
        </a-tree>
      </div>
      <!--      右边-->
      <div class="el-main" style="background: #fff; flex: 1; width: 50%">
        <div class="main_content">
          <div class="breadcrumb_mi">
            <a-breadcrumb>
              <a-breadcrumb-item v-for="(item, index) in result" :key="index">{{
                item
              }}</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <div class="title" style="">
            <h5>{{ messageObj.title }}</h5>
            <p>最近更新{{ messageObj.updateTime }}</p>
          </div>
          <div class="inner_area" v-html="messageObj.docContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inquireTree, gethelpcenter } from "@/api/HelpCenter";

export default {
  name: "DocumentCenter",
  components: {},
  data() {
    return {
      replaceFields: {
        children: "children",
        title: "name",
        key: "onlyFlag",
      },
      onlyFlages: this.$route.query.onlyFlag
        ? [this.$route.query.onlyFlag]
        : [],
      expandedKeys: [],
      selectedKeys: [],
      treeData: [],
      messageObj: {},
      targets: {}, // 目标
      result: [],
    };
  },
  created() {
    inquireTree(4).then((res) => {
      let { code, data } = res;
      if (200 === code) {
        this.treeData = data;
        this.findNode(this.onlyFlages[0]);
        this.findParent(data, this.targets, this.result);
      }
    });
    if (this.$route.query.onlyFlag) {
      gethelpcenter(this.$route.query.onlyFlag).then((res) => {
        let { data } = res;
        if (data) {
          this.messageObj = data;
        }
      });
    }
  },
  methods: {
    findNode(id) {
      for (let i = 0; i < this.treeData.length; i++) {
        if (this.treeData[i].children === null) {
          if (id === this.treeData[i].onlyFlag) {
            this.expandedKeys = [];
            this.selectedKeys = [this.treeData[i].onlyFlag];
            return;
          }
        } else {
          for (
            let index = 0;
            index < this.treeData[i].children.length;
            index++
          ) {
            if (id === this.treeData[i].children[index].onlyFlag) {
              this.targets = this.treeData[i].children[index];
              this.expandedKeys = [this.treeData[i].onlyFlag];
              this.selectedKeys = [this.treeData[i].children[index].onlyFlag];
              return;
            }
          }
        }
      }
    },
    onSelect(keys, event) {
      let onlyFlags = keys[0];
      gethelpcenter(onlyFlags).then((res) => {
        let { data } = res;
        if (data) {
          this.$router.push({
            path: "/documentcenter",
            query: { onlyFlag: onlyFlags },
          });
          this.messageObj = data;
          this.result = [];
          this.findParent(this.treeData, data, this.result);
        }
      });
    },

    // treeData：要遍历的数据， target：查找目标， result用于装查找结果的数组
    findParent(data, target, result) {
      for (let i in data) {
        let item = data[i];
        if (item.id === target.id) {
          //将查找到的目标数据加入结果数组中
          //可根据需求unshift(item.id)或unshift(item)
          result.unshift(item.name);
          return true;
        }
        if (item.children && item.children.length > 0) {
          let ok = this.findParent(item.children, target, result);
          if (ok) {
            result.unshift(item.name);
            return true;
          }
        }
      }
      //走到这说明没找到目标
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.inner_area ::v-deep p img {
  height: auto;
  max-width: 100%;
}
.el-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: calc(100% - 64px - 97px);
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}

.container_nav {
  position: relative;
  z-index: 1;
}

.el-aside {
  width: 300px !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 95px - 64px);
  padding-left: 30px;
  padding-top: 30px;
  background: #fafafa;
  transition: all 0.3s ease-in;
  overflow: auto;
}

.left_wd {
  font-size: 18px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
}

.el-main {
  padding: 0;
}

.main_content {
  height: calc(100vh - 95px - 64px);
  padding: 26px 50px 50px;
  overflow-y: scroll;
  .breadcrumb_mi {
    font-size: 18px;
    color: rgb(102, 102, 102);
    margin-bottom: 32px;
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 30px;

  h5 {
    font-size: 28px;
    line-height: 36px;
    color: #36383d;
    font-weight: 600;
    margin-bottom: 6px;
  }

  p {
    line-height: 26px;
    font-size: 18px;
    color: #999;
    padding-bottom: 18px;
    border-bottom: 0.5px solid #ddd;
  }
}

.inner_area {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.75;
  margin-bottom: 8px;
  margin-top: 0;
  word-break: break-word;
}
</style>
